<template>
  <div>
    <div class="left-table-container">
      <div class="top-info">
        <Title :title="'日志管理'"></Title>
      </div>
      <div class="search-box">
        <div class="search-conditions">
          <div class="info">
            <span class="lable">用户名称</span>

            <el-input v-model="name" style="width: 242px" placeholder="请输入用户名"></el-input>
          </div>
        </div>
        <div>
          <div class="">
            <el-button class="search-btn" @click="getList()">查询</el-button>
            <el-button class="btn-reset" @click="reset()">重置</el-button>
          </div>
        </div>
      </div>
      <!-- <div class="add-btn" style="padding-top: 24px">
        <el-button class="add" icon="el-icon-plus" @click="addNew()">新增</el-button>
      </div> -->
      <div class="body-content-box">
        <el-table
          :data="tableData"
          default-expand-all
          header-cell-class-name="table-header-cls"
          row-class-name="table-cell-cls"
          max-height="650"
        >
          <el-table-column type="selection" width="55" align="center"> </el-table-column>

          <el-table-column prop="name" label="登录账户">
            <!-- <template v-slot="scope">
              <div>{{ formatUser(scope.row.userId) }}</div>
            </template> -->
          </el-table-column>
          <el-table-column prop="ip" label="登录地址"> </el-table-column>
          <el-table-column prop="type" label="操作类型">
            <template v-slot="scope">
              <div>{{ scope.row.type == 'in' ? '登录' : '注销' }}</div>
            </template>
          </el-table-column>

          <el-table-column prop="createTime" label="创建时间" sortable>
            <template v-slot="scope">
              <div>{{ formatDate(scope.row.createTime) }}</div>
            </template>
          </el-table-column>

          <!-- <el-table-column label="操作" class-name="small-padding fixed-width">
            <template slot-scope="scope">
              <el-button class="operation-btn" type="text" @click="handleShow(scope.row)">查看 </el-button>
              <el-button class="operation-btn" type="text" @click="handleUpdate(scope.row)">编辑</el-button>
              <el-button class="btn-del" type="text" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column> -->
        </el-table>
        <div class="pagination-box">
          <el-pagination
            :current-page="queryParams.pageNum"
            background
            :page-size="queryParams.pageSize"
            :total="total"
            layout="total, prev, pager, next"
            @size-change="handlePageSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Title from './title.vue'
  import { Log, User } from '@/api/system/index'

  export default {
    name: '',
    components: {
      Title
    },
    props: {},
    data() {
      return {
        name: null,
        tel: null,
        tableData: [],
        userList: [],
        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 15
        },
        total: 0
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.getList()
    },
    methods: {
      /**
       * @description  获取用户列表
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      getList() {
        let params = {
          pageNum: this.queryParams.pageNum,
          pageSize: this.queryParams.pageSize
        }
        const loading = this.$loading({
          lock: true,
          text: '数据加载...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        Log.getList(params)
          .then((res) => {
            this.tableData = res.data.data.list
            this.total = res.data.data.total
            loading.close()
          })
          .catch((err) => {
            loading.close()
          })
        // User.user_list(params)
        //   .then((res) => {
        //     this.userList = res.data.data.list
        //   })
        //   .catch((err) => {
        //     loading.close()
        //   })
      },
      formatUser(val) {
        const user = this.userList.find((user) => {
          return user.id === val
        })
        if (user) return user.name
      },
      /**
       * @description 重置
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      reset() {
        this.name = null
        this.tel = null
        this.getList()
      },

      //分页时展示页面数据的容量
      handlePageSizeChange(val) {
        this.queryParams.pageSize = val
        this.getList()
      },
      //分页时切换页
      handleCurrentChange(val) {
        this.queryParams.pageNum = val
        this.getList()
      }
    }
  }
</script>
<style lang="less" scoped>
  .main {
    height: 100%;
  }
  .left-table-container {
    float: left;
    width: calc(100% - 48px);
    margin-top: 34px;
    margin-left: 24px;
    height: calc(100% - 58px);
    border-radius: 2px;
    background: rgba(255, 255, 255, 1);
    .top-info {
      margin: 0 auto;
      width: calc(100% - 48px);
    }
    .search-box {
      margin: 24px auto 0;
      top: 180px;
      left: 48px;
      width: calc(100% - 48px);
      height: 52px;
      // background-color: red;
      display: grid;
      grid-template-columns: 6.5fr 1fr;
      .search-conditions {
        display: grid;
        grid-template-columns: repeat(3, 33.3%);
        .info {
          height: 32px;
          line-height: 32px;
          .lable {
            margin-left: 22px;
            margin-right: 16px;
            color: rgba(0, 0, 0, 0.9);
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
    .add-btn {
      margin: 24px auto;
      width: calc(100% - 48px);
    }
    .body-content-box {
      margin: 0 auto;
      width: calc(100% - 48px);
      //   max-height: 558px;
      //   overflow: auto;
    }
  }
  .search-btn {
    width: 65px;
    height: 32px;
    color: #fff;
    background-color: rgba(64, 158, 255, 1);
    border-color: rgba(64, 158, 255, 1);
    border-radius: 2px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 2px;
    padding: 8px 16px !important;
  }
  .btn-reset {
    color: rgba(64, 158, 255, 1);
    background-color: #fff;
    border: 1px solid rgba(64, 158, 255, 1);
    border-radius: 2px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 2px;
    padding: 8px 16px !important;
  }
  .add {
    width: 85px;
    color: #fff;
    background-color: rgba(64, 158, 255, 1);
    border-color: rgba(64, 158, 255, 1);
    border-radius: 2px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 2px;
    padding: 8px 16px !important;
  }
  // 删除按钮
  .btn-del {
    color: #f52f3e;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    // padding:8px 16px;
  }
  /deep/ .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
  // 表格标题
  .table-header-cls {
    background: #f7f8fa !important;
    font-style: normal;
    font-weight: blod !important;
    font-size: 14px;
    height: 55px;
    color: #1d2129;
    text-align: left;
  }

  // 表格行数据
  .table-cell-cls {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4e5969;
    text-align: left;
    height: 55px;
  }
  // 页面分页条
  .pagination-box {
    padding-top: 4px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }
  .el-form-item {
    color: rgba(0, 0, 0, 0.9);
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
</style>
